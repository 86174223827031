<template>
  <page-main title="Career Pathway Analyzer" noBottom :displayBeta="true">
    <template v-slot:aside>
      <div>
        <ul>
          <p>
            Not sure what kinds of jobs you want to seek out? Use this tool to
            evaluate your resume and career interests in order to receive
            customized suggestions and feedback.
          </p>
          <li>
            To use, simply select any one of your previously saved resumes from
            the dropdown menu and (optionally) enter up to five career
            interests. Then all you need to do is submit your request, and
            you'll be provided with a customized response.
          </li>
          <li>
            Be sure to download your report if you want to keep it! Career
            pathway analyses are NOT saved by Resumation.
          </li>
          <li>
            This feature is available to Resumation Plus subscribers only.
          </li>
          <li>This feature is limited to 50 uses per month.</li>
        </ul>
      </div>
    </template>
    <template v-slot:nav>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <ul>
          <li>
            <span class="font-weight-bold">Tip #1:</span> Mix and match your
            saved resumes with your career interests to receive different job
            recommendations.
          </li>
          <li>
            <span class="font-weight-bold">Tip #2:</span> Don't know what
            version of your resume to use? Use the one with the most information
            to get started.
          </li>
          <li>
            <span class="font-weight-bold">Tip #3:</span> Use this feature to
            get ideas on what jobs to consider. Once you have some suggestions,
            use Resumation's Job Fit Analyzer tool to see how your resume stacks
            up against any job description (and receive suggestions on how it
            can improve)!
          </li>
        </ul>
      </modal>
      <v-btn outlined class="mt-4" block @click="navToContactUs()">
        Any feedback? Contact us!
      </v-btn>
    </template>
    <v-container fluid class="container-main">
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="selectedResume"
            :items="resumes"
            item-text="name"
            item-value="id"
            label="Select Saved Resume (Drop-down)"
            tabindex="1"
            @change="resumeChange"
            autofocus
          ></v-select>
        </v-col>
        <v-col cols="12">
          <multi-select
            label="Career Interests"
            :selected.sync="careerInterestsLocal"
            :optionsList="baseCareerInterests"
            tabindex="2"
            :maxSelections="25"
            autofocus
          ></multi-select>
        </v-col>
        <v-col
          cols="12"
          class="text-center d-flex justify-space-between align-center"
        >
          <v-btn
            :disabled="!selectedResume || isAnalyzing"
            :class="{ 'grey-btn': !selectedResume }"
            @click="optimizeWithAI"
            tabindex="3"
          >
            Submit
          </v-btn>
          <div>
            {{ careerPathwayAttempts }} /
            {{ maxCareerPathwayAttempts }} analyses submitted for the month
          </div>
        </v-col>
        <v-col cols="12" style=" padding-bottom: 48px;">
          <v-card
            v-if="isAnalyzing || aiOptimizedPhrasing || showAiOptimizationError"
            class="pa-3"
          >
            <v-row>
              <v-col cols="12" class="d-flex justify-space-between">
                <span><b>Career Pathway Analysis</b></span>
                <v-btn
                  v-if="
                    !isAnalyzing &&
                      aiOptimizedPhrasing &&
                      !showAiOptimizationError
                  "
                  small
                  @click="downloadReport"
                  >CLICK TO DOWNLOAD ANALYSIS
                </v-btn>
              </v-col>
              <v-col cols="12">
                <div v-if="isAnalyzing" class="text-center">
                  <p><b>Analyzing...</b></p>
                  <v-img
                    aspect-ratio="1"
                    width="50"
                    max-width="50"
                    class="mx-auto"
                    :src="require('@/assets/logos/logo_blue_1.png')"
                    contain
                  ></v-img>
                  <h3 class="text-body-1 font-italic text--secondary">
                    This may take a minute to complete
                  </h3>
                </div>
                <div v-else-if="showAiOptimizationError">
                  <v-card-text
                    class="text--primary text-body-1 pt-4 pb-3 text-center"
                  >
                    <h3 class="pt-2">Oops. Something went wrong.</h3>
                    <h3 class="text-body-1 font-italic text--secondary">
                      {{ errorMessage }}
                    </h3>
                  </v-card-text>
                  <v-card-actions
                    :class="
                      $vuetify.breakpoint.xsOnly
                        ? 'flex-wrap justify-center'
                        : 'flex-wrap justify-end'
                    "
                  >
                  </v-card-actions>
                </div>
                <div v-else>
                  <p>Generated: {{ new Date().toLocaleDateString() }}</p>
                  <p>Based on Resume: {{ selectedResumeName }}</p>
                  <p><b>AI Response:</b></p>
                  <div
                    v-for="(item, i) in aiOptimizedDisplay"
                    style="white-space: pre-wrap;"
                    :key="i"
                  >
                    {{ item }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          class="text-center"
          v-if="aiOptimizedPhrasing && !isAnalyzing && !showAiOptimizationError"
        >
          <v-btn @click="resetDialog = true">Reset</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="resetDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to reset this page?</v-card-title
        >
        <v-card-text
          >You will lose the analysis you generated. Make sure you download your
          analysis if you want to reference it later.</v-card-text
        >
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="resetResumeState"
            >No - Return to Analysis</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetCareerReport"
            >Yes - Reset</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <r-dialog v-model="premiumBanner" persistent>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <h4 class="pt-2 text-center">
          Oops! You will need Resumation Plus to use the Career Pathway Analyzer
          feature!
        </h4>
        <p class="mt-2">
          Upgrade to get unlimited use of the Optimize with AI features,
          hide/show parts of your resume in-tool (customizations), save and
          store multiple resumes, and much more for only $5 a month!
        </p>
        <h4 class="pt-2 text-center">
          Would you like to upgrade to Resumation Plus?
        </h4>
      </v-card-text>
      <v-card-actions
        :class="
          $vuetify.breakpoint.xsOnly
            ? 'flex-wrap justify-center'
            : 'flex-wrap justify-end'
        "
      >
        <v-btn color="gray" outlined text @click="noSubscription()">
          Not Now
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined text @click="navToPremiumPage()">
          Yes - let's do it!
        </v-btn>
      </v-card-actions>
    </r-dialog>
  </page-main>
</template>

<script>
import MultiSelect from "@/components/MultiSelect.vue";
import * as resApi from "../../api/ResumeApi.js";
import * as log from "../../utility/logging/logger.js";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import * as Display from "../../utility/display.js";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import CareerReportBuilder from "../../docxBuilder/careerReportBuilder.js";
import { saveAs } from "file-saver";

export default {
  data: () => ({
    premiumBanner: false,
    aiOptimizedPhrasing: "",
    aiOptimizedDisplay: "",
    phrasing: "",
    context: "career-pathway-analyzer",
    aiOptimizationStatus: "idle",
    errorMessage: "",
    selectedResume: "",
    previousSelectedResume: "",
    isAnalyzing: false,
    resetDialog: false,
    resetResume: true,
    careerInterestsLocal: [],
    careerInterestsDisplayLocal: "",
  }),
  components: {
    "multi-select": MultiSelect,
  },
  methods: {
    noSubscription() {
      this.$router.back();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("auth", ["refreshPremium"]),
    ...mapActions("userSubmissions", ["loadResumeSubmissions"]),
    ...mapActions("careerInterest", ["saveCareerInterests"]),
    resetCareerReport() {
      this.aiOptimizationStatus = "idle";
      this.resetDialog = false;
      this.isAnalyzing = false;
      this.aiOptimizedPhrasing = "";
      if (this.resetResume) {
        this.selectedResume = "";
      }
      this.resetResume = true;
    },
    resetResumeState() {
      this.selectedResume = this.previousSelectedResume;
      this.resetDialog = false;
      this.resetResume = true;
    },
    resumeChange() {
      if (this.aiOptimizedPhrasing !== "" || this.isAnalyzing == true) {
        this.resetDialog = true;
        this.resetResume = false;
      } else {
        this.previousSelectedResume = this.selectedResume;
      }
    },
    buildReportDisplay() {
      const text = this.aiOptimizedPhrasing;
      const lines = text.split("\n");
      this.aiOptimizedDisplay = lines;
    },
    async downloadReport() {
      try {
        log.logInformation("Building report docx.");
        const builder = new CareerReportBuilder();
        builder.CreateText(this.aiOptimizedPhrasing, this.selectedResumeName);
        const blob = await builder.BuildDocx("Career Pathway Analysis");
        saveAs(blob, "CareerPathwayAnalysis.docx");
      } catch (e) {
        log.logError("Failed to build downloaded docx", e);
        this.aiOptimizationStatus = "error";
        this.errorMessage = "Error Building downloadable docx.";
      }
    },
    async optimizeWithAI() {
      this.isAnalyzing = true;
      try {
        log.logInformation("Refreshing user premium and attempts status...");
        await this.refreshPremium();

        if (!this.isPremium) {
          this.dialog = false;
          this.premiumBanner = true;
          this.isAnalyzing = false;
        } else {
          let reportData = await this.buildReportDataString();
          if (reportData == "Error") {
            return null;
          }
          let result = await resApi.FetchAiOptimizedPhrasing({
            resumeId: this.$store.state.resumeId,
            phrasing: reportData,
            context: this.context,
          });
          if (!result.success) {
            log.logError(
              "Failed to get AI optimized description",
              "optimizeWithAI"
            );
            this.aiOptimizationStatus = "error";
            this.errorMessage = "Failed to get AI Response";
            if (result.displayText == "Max Attempts Reached") {
              this.errorMessage =
                "Failed to get AI Response: Max usage reached.";
            }
          } else {
            if (this.isAnalyzing == true) {
              this.aiOptimizedPhrasing = result.data.optimizedPhrasing;
              this.buildReportDisplay();
            }
          }
          this.isAnalyzing = false;
          await this.refreshPremium();
        }
      } catch (e) {
        log.logError("Failed to get AI optimized description", e);
        this.aiOptimizationStatus = "error";
      }
    },
    async buildReportDataString() {
      const resume = this.resumes.find((r) => r.id === this.selectedResume);
      if (!resume) {
        return "Error";
      }
      const fileRef = resume.fileRef;
      const response = await fetch(fileRef);
      const arrayBuffer = await response.arrayBuffer();
      const zip = new PizZip(arrayBuffer);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });
      const resumeText = doc.getFullText();
      this.findDisplayValues();
      const careerInterestsText = this.careerInterestsDisplayLocal;
      let result = resumeText.concat(
        "Career Interests: " + careerInterestsText
      );
      return result;
    },
    async loadData() {
      try {
        await this.refreshPremium();
        await this.loadResumeSubmissions();
        this.careerInterestsLocal = [...this.careerInterests];
      } catch (e) {
        log.logError("Failed to load resumes", e);
      }
    },
    findDisplayValues() {
      this.careerInterestsDisplayLocal = Display.BuildCommaString(
        "",
        this.careerInterestsLocal
      );
    },
    navToPremiumPage() {
      this.dialog = false;
      this.$router.push({ path: "/Upgrade" });
    },
    navToContactUs() {
      window.open("https://www.resumation.net/contact", "_blank");
    },
  },
  computed: {
    selectedResumeName() {
      const resume = this.resumes?.find(
        (r) => r.id === this.previousSelectedResume
      );
      return resume ? resume.name : "No resume selected";
    },
    showAiOptimizationError() {
      return this.aiOptimizationStatus === "error";
    },
    ...mapFields("auth", {
      isPremium: "isPremium",
      remainingAiOptimizerAttempts: "remainingAiOptimizerAttempts",
      maxCareerPathwayAttempts: "maxCareerPathwayAttempts",
      careerPathwayAttempts: "careerPathwayAttempts",
    }),
    ...mapFields("userSubmissions", {
      resumes: "resumes",
    }),
    ...mapFields("careerInterest", ["careerInterests", "baseCareerInterests"]),
  },
  async mounted() {
    await this.loadData();
    if (!this.isPremium) {
      this.dialog = false;
      this.premiumBanner = true;
    }
  },
};
</script>
